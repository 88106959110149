<template>
  <v-app>
    <v-main class="background">
      <v-container fluid>
        <Snackbar :text="error_message" v-if="snack" :timeout="5000"></Snackbar>

        <v-row align="center" justify="center" fluid>
          <v-col cols="12" xl="12">
            <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              v-model="delete_flag"
              type="warning"
              dismissible
              rounded
              style="border-radius: 30px"
              close-text="Close"
              class="mt-4"
            >
              <v-row align="center">
                <v-col class="grow">
                  {{ deleteData.message }}
                </v-col>
                <v-col class="shrink">
                  <v-btn class="error" @click="deleteUrl()"
                    >Please Delete</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
            <v-card class="elevatin-0 mt-5 rounded-t-xl rounded-b-xl" outlined>
              <v-card-title>
                <v-text-field
                  outlined
                  rounded
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-btn
                  color="primary"
                  class="elevation-0 ml-2"
                  rounded
                  large
                  @click="dialog = true"
                >
                  <v-icon color="white" class="mr-2">mdi-plus</v-icon> New URL
                </v-btn>
              </v-card-title>
              <v-data-table
                calculate-widths:true
                :search="search"
                v-model="selected"
                :headers="$store.state.linkheaders"
                :items="orderedSteps"
                item-key="name"
                :loading="loadURLs"
                no-data-text="No URLs Found"
                no-results-text="No URLs Found for the given search term"
                class="elevation-0"
              >
                <template v-slot:item.privacy_enabled="{ item }">
                  <v-icon color="green" v-if="item.privacy_enabled">
                    mdi-lock
                  </v-icon>
                  <v-icon v-else color="red"> mdi-lock-off</v-icon>
                </template>
                <template v-slot:item.actions="props">
                  <v-menu offset-y open-on-click>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        @click="$router.push('/links/' + props.item.id)"
                      >
                        <v-icon color="primary"> mdi-cog</v-icon>
                      </v-btn>
                      <v-btn
                        @click="
                          confirmdeleteUrl(
                            props.item.id,
                            props.item.shortURL,
                            props.item.createdBy
                          )
                        "
                        rounded
                        icon
                      >
                        <v-icon color="red"> mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card class="elevation-0">
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Create New URL</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <!-- <v-btn dark text @click="dialog = false"> Save </v-btn> -->
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12" xl="6" lg="8">
                  <v-card class="elevation-0" outlined>
                    <v-card-text>
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12" class="mt-n2">
                            <v-text-field
                              label="Long URL *"
                              required
                              :rules="mandatory"
                              v-model="linkdata.longURL"
                              @change="sanitize_url(linkdata.longURL)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="mt-n4">
                            <v-text-field
                              label="Base URL *"
                              required
                              :rules="mandatory"
                              v-model="linkdata.baseURL"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="mt-n4">
                            <v-text-field
                              label="Short Code *"
                              required
                              :rules="mandatory"
                              v-model="linkdata.shortCode"
                              :loading="loading"
                              @input="makeURL(linkdata.shortCode)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="12" lg="12" class="mt-n4">
                            <v-text-field
                              label="Short URL (Auto-generated)"
                              required
                              disabled
                              v-model="linkdata.shortURL"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" xl="6" lg="6" class="mt-n5">
                            <v-switch
                              v-model="linkdata.privacy_enabled"
                              :label="`Enable Password Protection for this URL`"
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="mt-n5">
                            <v-text-field
                              label="Password for URL"
                              required
                              style="border-radius: 10px"
                              v-model="linkdata.hash"
                              :disabled="!linkdata.privacy_enabled"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="mt-n5">
                            <v-switch
                              disabled
                              v-model="linkdata.expiry_enabled"
                              :label="`Automatically Expire this URL`"
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" class="mt-n5">
                            <v-text-field
                              label="Select the Date (Post this, URL will be deleted)"
                              required
                              style="border-radius: 10px"
                              :disabled="!linkdata.expiry_enabled"
                              v-model="linkdata.expiry"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                  <v-card-actions class="mt-1">
                    <v-card-text style="color: red" v-if="duplicate_flag"
                      ><v-icon color="error">mdi-alert-outline</v-icon>
                      {{ duplicate_message }}
                    </v-card-text>
                    <v-spacer></v-spacer>

                    <v-btn color="primary" text @click="dialog = false">
                      Cancel
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="createUrl()"
                      :disabled="duplicate_flag || !filteredLinks"
                    >
                      Create URL
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LinkService from "@/services/LinkService.js";
import Snackbar from "../../components/Snackbar.vue";
import _ from "lodash";

export default {
  components: {
    Snackbar,
  },
  data: () => ({
    dialog: false,
    links: [],
    selected: [],
    loadURLs: false,
    access_Token: "",
    loading: false,
    duplicate_flag: false,
    duplicate_message: "",
    delete_flag: false,
    search: "",
    snack: false,
    error_message: "",
    deleteData: {
      message: "",
      id: "",
    },
    linkdata: {
      longURL: "",
      baseURL: "https://clabs.app/",
      shortCode: "",
      shortURL: "",
      id: "",
      expiry: "" || "0000000000",
      privacy_enabled: false,
      hash: "",
      expiry_enabled: false,
      createdBy: localStorage.getItem("name"),
    },

    privacy_dd: [
      {
        text: "I want to keep URL Publically Accessible",
        value: false,
      },
      {
        text: "I want only users with the password to be accessible",
        value: true,
      },
    ],
    expiry_dd: [
      {
        text: "I want to delete URL after a specific date",
        value: true,
      },
      {
        text: "I want to keep URL Indefinitely Working",
        value: false,
      },
    ],
    /* Rules for Form Validation */
    mandatory: [(v) => !!v || "Name is required"],
  }),
  created() {},
  beforeMount() {},
  mounted() {
    this.getLinks();
  },
  computed: {
    filteredLinks() {
      return this.fieldsChecker();
    },
    orderedSteps: function () {
      return _.orderBy(this.links, "createdAt", "asc");
    },
  },
  methods: {
    getLinks() {
      let that = this;
      that.loadURLs = true;
      that.access_Token = localStorage.getItem("accessToken");
      LinkService.ListURLs(this.access_Token)
        .then((response) => {
          that.links = response;
          for (let i = 0; i < that.links.length; i++) {
            that.links[i].linkcreatedAt = new Date(
              parseInt(that.links[i].linkcreatedAt)
            ).toLocaleString();
          }
          that.loadURLs = false;
        })
        .catch((error) => {
          that.loadURLs = false;
          if (error == 401 || error == 403) {
            this.snack = true;
            this.error_message =
              "You are not permitted to list the links, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.error_message =
              "Something Went Wrong While Pulling the Link List";
          }
        });
    },

    makeURL(shortCode) {
      let that = this;
      that.linkdata.shortURL = that.linkdata.baseURL + shortCode;
      that.checkDuplicate(shortCode);
    },

    checkDuplicate(shortCode) {
      let that = this;
      that.duplicate_flag = false;
      LinkService.CheckDuplicate(shortCode, this.access_Token)
        .then((response) => {
          if (response.data.duplicate) {
            that.duplicate_flag = true;
            that.duplicate_message =
              "The Short code `" +
              shortCode +
              "` is being already used in the short url generated by " +
              response.data.link.createdBy +
              ". Please choose another one or reach out to " +
              response.data.link.createdBy;
          } else {
            that.duplicate_flag = false;
          }
        })
        .catch((error) => {
          that.duplicate_flag = false;
          if (error == 401 || error == 403) {
            this.snack = true;
            this.error_message =
              "You are not permitted to check if link is Duplicate, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.error_message =
              "Something Went Wrong While checking if the link is duplicate";
          }
        });
    },

    fieldsChecker() {
      if (
        this.linkdata.longURL.length > 0 &&
        this.linkdata.shortCode.length > 0
      ) {
        if (this.linkdata.privacy_enabled && this.linkdata.hash.length > 0) {
          return true;
        } else if (!this.linkdata.privacy_enabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    createUrl() {
      let that = this;
      LinkService.CreateLink(that.linkdata, that.access_Token)
        .then((response) => {
          console.log(response);
          that.dialog = false;
          that.getLinks();
        })
        .catch((error) => {
          if (error == 401 || error == 403) {
            this.snack = true;
            this.error_message =
              "You are not permitted to create link, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.error_message = "Something Went Wrong While creating the link";
          }
        });
    },

    confirmdeleteUrl(id, shortURL, createdBy) {
      let that = this;
      that.delete_flag = true;
      that.deleteData.id = id;
      that.deleteData.message =
        'Do you really want to delete URL "' +
        shortURL +
        '" created by "' +
        createdBy +
        '" as well as analytical logs?, This action cannot be undone.';
    },

    deleteUrl() {
      let that = this;
      LinkService.DeleteLink(that.deleteData.id, that.access_Token)
        .then(() => {
          that.delete_flag = false;
          this.DeleteInsights(that.deleteData.id);
        })
        .catch((error) => {
          if (error == 401 || error == 403) {
            this.snack = true;
            this.error_message =
              "You are not permitted to delete link, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.error_message = "Something Went Wrong While deleting the link";
          }
        });
    },

    DeleteInsights(id) {
      let that = this;
      LinkService.DeleteLog(id, that.access_Token)
        .then(() => {
          that.getLinks();
        })
        .catch((error) => {
          if (error == 401 || error == 403) {
            this.snack = true;
            this.error_message =
              "You are not permitted to delete link analytics, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.error_message =
              "Something Went Wrong While deleting the link analytics";
          }
        });
    },

    sanitize_url(url) {
      let that = this;
      that.linkdata.longURL = url.replace(/\s/g, "");
    },
  },
};
</script>
