<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <Snackbar :text="error_message" v-if="snack" :timeout="5000"></Snackbar>
        <v-row fluid>
          <v-col cols="12" lg="8" xl="8">
            <v-card class="elevatin-0 rounded-t-xl rounded-b-xl" outlined>
              <v-data-table
                :headers="$store.state.userheaders"
                :items="list"
                item-key="name"
                :loading="loadURLs"
                class="elevation-0"
              >
                <template v-slot:item.avatar="{ item }">
                  <div class="p-2">
                    <v-avatar size="40">
                      <v-img :src="item.avatar" :alt="item.username"></v-img>
                    </v-avatar>
                  </div>
                </template>
                <template v-slot:item.isactive="{ item }">
                  <v-chip
                    v-if="(item.isactive = 'true')"
                    class="ma-2"
                    color="green"
                    dark
                  >
                    Active
                  </v-chip>
                  <v-chip v-else class="ma-2" color="error"> Suspended </v-chip>
                </template>
                <template v-slot:item.actions="props">
                  <v-btn
                    icon
                    @click="$router.push('/admin/users/' + props.item.username)"
                  >
                    <v-icon color="button"> mdi-cog</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" lg="4" xl="4">
            <v-card class="elevation-0 rounded-t-xl rounded-b-xl">
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="userdata.firstname"
                      label="First Name"
                      required
                      outlined
                      style="border-radius: 10px"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="userdata.lastname"
                      label="Last Name"
                      required
                      outlined
                      style="border-radius: 10px"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-text-field
                      v-model="userdata.username"
                      label="Username"
                      required
                      outlined
                      style="border-radius: 10px"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-text-field
                      v-model="userdata.password"
                      label="Password"
                      required
                      type="password"
                      outlined
                      style="border-radius: 10px"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="mt-n8">
                    <v-overflow-btn
                      class="elevation-0"
                      :items="categories"
                      label="Organization Department"
                      outlined
                      v-model="type"
                      @change="getDepartments()"
                      style="border-radius: 10px"
                    ></v-overflow-btn>
                  </v-col>
                  <v-col cols="12" class="mt-n8">
                    <v-overflow-btn
                      class="elevation-0"
                      :items="departments"
                      label="Organization Sub-Department"
                      outlined
                      style="border-radius: 10px"
                      v-model="userdata.com_unit_id"
                    ></v-overflow-btn>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-overflow-btn
                      class="elevation-0"
                      :items="[
                        {
                          text: 'System Administrator',
                          value: 'System Administrator',
                        },
                        { text: 'User', value: 'User' },
                      ]"
                      label="User Role"
                      outlined
                      style="border-radius: 10px"
                      v-model="userdata.role"
                    ></v-overflow-btn>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-overflow-btn
                      class="elevation-0"
                      :items="[
                        { text: 'Active', value: 'true' },
                        { text: 'Suspended', value: 'false' },
                      ]"
                      label="Account Status"
                      outlined
                      style="border-radius: 10px"
                      v-model="userdata.isActive"
                    ></v-overflow-btn>
                  </v-col>

                  <v-col cols="12" class="mt-n8">
                    <v-btn
                      class="elevation-0"
                      color="primary"
                      block
                      rounded
                      :disabled="!formComplete"
                      @click="addUser()"
                    >
                      <v-icon left> mdi-account-plus</v-icon>
                      Add User
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-overlay :value="not_allowed" opacity="0.85">
          <v-card
            class="mx-auto rounded-t-xl rounded-b-xl"
            style="border-radius: 10px"
            light
            max-width="425"
            align="center"
          >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon> <v-icon color="error">mdi-close</v-icon></v-btn>
            </v-card-actions>
            <v-img
              width="70%"
              src="https://cdn3d.iconscout.com/3d/premium/thumb/young-boy-character-falling-from-sky-4629045-3855692.png"
            ></v-img>

            <v-card-text>
              <h2 class="text-h6 error--text" style="font-weight: bolder">
                Action Prohibited
              </h2>
              You are not allowed to perform this action, please reach out to
              System Administrator if you think this is an error.
            </v-card-text>
          </v-card>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Admin from "@/services/Admin.js";
import Snackbar from "../../components/Snackbar.vue";
import md5 from "md5";
export default {
  components: {
    Snackbar,
  },

  data: () => ({
    loading: false,
    not_allowed: false,
    accessToken: "",
    list: [],
    units: [],
    categories: [],
    departments: [],
    loadURLs: false,
    error_message: "",
    snack: false,

    type: "",
    userdata: {
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      role: "",
      com_unit_id: "",
      role_id: 0,
      isActive: "",
      avatar: "",
    },
  }),
  created() {},
  computed: {
    formComplete() {
      return this.fieldsChecker();
    },
    useravatar() {
      return md5(this.userdata.username);
    },
  },
  beforeMount() {},
  mounted() {
    this.getUsers();
    this.listUnits();
  },
  methods: {
    getUsers() {
      let that = this;
      that.accessToken = localStorage.getItem("accessToken");
      Admin.ListUsers(that.accessToken)
        .then(function (response) {
          that.list = response.users;
        })
        .catch(function (error) {
          if (error === 403) {
            that.error_message = "You are not authorized to access this page";
            that.snack = true;
            that.not_allowed = true;
            this.countDownTimer();
          } else {
            that.error_message = "Something went wrong. Please try again later";
            that.snack = true;
          }
        });
    },

    listUnits() {
      let that = this;
      Admin.ListUnits()
        .then(function (response) {
          that.units = response;
          // Push uniqe categories to categories array
          that.units.forEach((unit) => {
            if (!that.categories.includes(unit.com_unit_category)) {
              that.categories.push(unit.com_unit_category);
            }
          });
        })
        .catch(function (error) {
          if (error === 403) {
            that.error_message = "You are not authorized to access this page";
            that.snack = true;
          } else {
            that.error_message = "Something went wrong. Please try again later";
            that.snack = true;
          }
        });
    },

    getDepartments() {
      let that = this;
      that.departments = [];
      //push all the objects matching the type value in units
      let shit = that.units.filter(
        (unit) => unit.com_unit_category === that.type
      );

      for (let i = 0; i < shit.length; i++) {
        let obj = {};
        obj["text"] = shit[i].com_unit_name;
        obj["value"] = shit[i].com_unit_id;
        that.departments.push(obj);
      }
    },

    addUser() {
      let that = this;
      that.userdata.avatar = that.useravatar;
      Admin.CreateUser(that.userdata, that.accessToken)
        .then((res) => {
          console.log(res);
          let payload = {
            username: that.userdata.username,
            status: "0",
          };
          Admin.SeedPermissions(payload, that.accessToken)
            .then((seedResp) => {
              console.log("SeedResp", seedResp);
            })
            .catch((seedError) => {
              console.log("SeedError", seedError);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fieldsChecker() {
      if (
        this.userdata.firstname === "" ||
        this.userdata.lastname === "" ||
        this.userdata.username === "" ||
        this.userdata.password === "" ||
        this.userdata.role === "" ||
        this.userdata.role_id === "" ||
        this.userdata.isActive === "" ||
        this.userdata.com_unit_id === ""
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
