import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin/Admin.vue";
import Users from "../views/Admin/Users.vue";
import User_Details from "../views/Admin/User_Details.vue";
import Login from "../views/Authentication/Login.vue";
import Login_Trouble from "../views/Authentication/Trouble.vue";
import Links from "../views/Links/Links.vue";
import Link_Details from "../views/Links/Link_Details.vue";
import Init from "../views/Admin/Init.vue";
import No_License from "../views/Admin/No_License";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      title: "Login",
    },
  },
  {
    path: "/Login_Trouble",
    name: "Login_Trouble",
    component: Login_Trouble,
    meta: {
      requiresAuth: false,
      title: "Login_Trouble",
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Home",
    },
  },
  {
    path: "/links",
    name: "Links",
    component: Links,
    meta: {
      requiresAuth: true,
      title: "Links",
    },
  },
  {
    path: "/links/:id",
    name: "Link_Details",
    component: Link_Details,
    meta: {
      requiresAuth: true,
      title: "Link Details",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      title: "Admin",
    },
  },
  {
    path: "/admin/users",
    name: "Users",
    component: Users,
    meta: {
      requiresAuth: true,
      title: "Users",
    },
  },
  {
    path: "/admin/users/:id",
    name: "User_Details",
    component: User_Details,
    meta: {
      requiresAuth: true,
      title: "User Details",
    },
  },
  {
    path: "/onboard/init/",
    name: "System Initialization",
    component: Init,
    meta: {
      requiresAuth: false,
      title: "Fresh Install",
    },
  },
  {
    path: "/onboard/no-license/",
    name: "System Initialization",
    component: No_License,
    meta: {
      requiresAuth: false,
      title: "Fresh Install",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - CareerLabs URL Shortner";
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.auth_status) {
      next({
        name: "Login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
