<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <Snackbar
          :text="error_message"
          v-if="snack"
          :timeout="5000"
          :color="snack_color"
        ></Snackbar>

        <v-card class="elevation-0 rounded-t-xl rounded-b-xl">
          <v-card-title>
            <v-btn
              small
              icon
              color="primary"
              class="mr-2"
              rounded
              outlined
              @click="$router.go(-1)"
              ><v-icon small>mdi-arrow-left-thick</v-icon>
            </v-btn>
            {{ link_meta.shortURL }}
          </v-card-title>
          <v-card class="elevation-0">
            <v-card class="elevation-0 rounded-t-xl rounded-b-xl">
              <v-row>
                <v-col cols="12" lg="5" xl="5">
                  <v-container>
                    <v-card class="elevation-0">
                      <v-container class="mt-3">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              label="Long URL*"
                              required
                              outlined
                              @change="sanitize_url(link_meta.longURL)"
                              :loading="text_loading"
                              style="border-radius: 10px"
                              v-model="link_meta.longURL"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="mt-n8"
                            ><v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" class="mt-n5">
                            <small
                              >Short URL Parameters (Can not be edited)</small
                            >
                          </v-col>
                          <v-col cols="6" class="mt-n2">
                            <v-text-field
                              label="Base URL*"
                              required
                              outlined
                              style="border-radius: 10px"
                              disabled
                              v-model="link_meta.baseURL"
                              :loading="text_loading"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="mt-n2">
                            <v-text-field
                              label="Short Code*"
                              required
                              style="border-radius: 10px"
                              outlined
                              disabled
                              v-model="link_meta.shortCode"
                              :loading="text_loading"
                              @input="makeURL(link_meta.shortCode)"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="mt-n8">
                            <v-text-field
                              label="Short URL"
                              style="border-radius: 10px"
                              required
                              outlined
                              disabled
                              :loading="text_loading"
                              v-model="link_meta.shortURL"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" class="mt-n8"
                            ><v-divider></v-divider>
                          </v-col>
                          <v-col cols="12" class="mt-n5">
                            <small class="mr-4">URL Privacy and Security</small>
                          </v-col>
                          <v-col cols="6" class="mt-n5">
                            <v-switch
                              v-model="link_meta.privacy_enabled"
                              :loading="text_loading"
                              :label="`Enable Password Protection for this URL`"
                            ></v-switch>
                          </v-col>
                          <v-col cols="6" class="mt-n5">
                            <v-switch
                              disabled
                              v-model="link_meta.expiry_enabled"
                              :loading="text_loading"
                              :label="`Automatically Expire this URL after Selected Date`"
                            ></v-switch>
                          </v-col>
                          <v-col cols="6" class="mt-n3">
                            <v-text-field
                              label="Password"
                              required
                              style="border-radius: 10px"
                              outlined
                              :disabled="!link_meta.privacy_enabled"
                              :loading="text_loading"
                              v-model="link_meta.hash"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" class="mt-n3">
                            <v-text-field
                              label="Date of Expiry"
                              required
                              style="border-radius: 10px"
                              outlined
                              :disabled="!link_meta.expiry_enabled"
                              v-model="link_meta.expiry"
                              :loading="text_loading"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-btn
                          rounded
                          color="primary"
                          class="mt-3 elevation-0"
                          @click="editLink()"
                          :disabled="!filteredLinks"
                        >
                          Save</v-btn
                        >
                      </v-container>
                    </v-card>
                  </v-container>
                </v-col>
                <v-col cols="12" lg="7" xl="7">
                  <v-container fluid>
                    <v-card class="elevation-0" align="center">
                      <v-responsive :aspect-ratio="16 / 8" class="mx-auto">
                        <v-img
                          :src="require('@/assets/track.svg')"
                          v-if="no_data"
                          contain
                          width="60%"
                        >
                        </v-img>
                        <apexchart
                          v-else
                          height="550"
                          type="area"
                          :options="options"
                          :series="series"
                        ></apexchart>
                      </v-responsive>
                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
/* eslint-disable */

import LinkService from "@/services/LinkService.js";
import VueApexCharts from "vue-apexcharts";
import Snackbar from "../../components/Snackbar.vue";

export default {
  components: {
    ApexCharts: VueApexCharts,
    Snackbar,
  },
  data: () => ({
    accessToken: "",
    id: "",
    no_data: false,
    snack: false,
    snack_color: "",
    error_message: "",

    link_meta: {
      baseURL: "",
      createdAt: "",
      createdBy: "",
      expiry: "",
      expiry_enabled: "",
      hash: "",
      id: "",
      longURL: "",
      privacy_enabled: null,
      shortCode: "",
      shortURL: "",
      unit_id: null,
    },
    text_loading: false,
    insights: [],
    axis: [],
    data: [],
    options: {},
    series: [],
  }),
  created() {},
  computed: {
    filteredLinks() {
      return this.fieldsChecker();
    },
  },
  beforeMount() {},
  mounted() {
    this.accessToken = localStorage.getItem("accessToken");
    this.FetchLinkData(this.accessToken);
    this.renderGraph(this.accessToken);
  },
  methods: {
    FetchLinkData() {
      this.id = this.$route.params.id;
      this.text_loading = true;
      LinkService.FetchLinkData(this.id, this.accessToken)
        .then((response) => {
          this.link_meta.longURL = response.data.longURL;
          this.link_meta.shortCode = response.data.shortCode;
          this.link_meta.shortURL = response.data.shortURL;
          this.link_meta.baseURL = response.data.baseURL;
          this.link_meta.createdAt = response.data.createdAt;
          this.link_meta.createdBy = response.data.createdBy;
          this.link_meta.expiry = response.data.expiry;
          this.link_meta.expiry_enabled = response.data.expiry_enabled;
          this.link_meta.hash = response.data.hash;
          this.link_meta.id = response.data.id;
          this.link_meta.privacy_enabled = response.data.privacy_enabled;
          this.link_meta.unit_id = response.data.unit_id;
          this.text_loading = false;
        })
        .catch((error) => {
          this.text_loading = false;
          if (error == 401 || error == 403) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "You are not permitted to View Link Details, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "Something Went Wrong While Pulling the Link Details";
          } else if (error == 404) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message = "This link no longer exists in the system";
          }
        });
    },

    renderGraph(accessToken) {
      let that = this;
      LinkService.RenderGraph(that.id, that.accessToken)
        .then((res) => {
          if (res.data.length == 0) {
            that.no_data = true;
            this.snack = true;
            this.snack_color = "warning";

            this.error_message =
              "This links Analytics data is not available, please try later";
          }
          that.insights = res.data;
          that.axis = that.insights.map((item) => item.date);
          that.data = that.insights.map((item) => item.count);
          that.options = {
            chart: {
              id: "Daily Link Clicks",
            },
            xaxis: {
              categories: that.axis,
            },
          };
          that.series = [
            {
              name: "Daily Visitors",
              data: that.data,
            },
          ];
        })
        .catch((error) => {
          if (error == 401 || error == 403) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "You are not permitted to check the Analytics, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "Something Went Wrong While Pulling the Link Analytics";
          } else if (error == 404) {
            that.no_data = true;
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "This links Analytics data is not available, please try later";
          }
        });
    },
    makeURL(shortCode) {
      let that = this;
      that.link_meta.shortURL = that.link_meta.baseURL + shortCode;
    },

    editLink() {
      let that = this;
      LinkService.UpdateLink(that.link_meta, that.accessToken)
        .then((res) => {
          alert("Link Updated Successfully");
          that.FetchLinkData(that.accessToken);
          that.renderGraph(that.accessToken);
        })
        .catch((error) => {
          if (error == 401 || error == 403) {
            this.snack = true;
            this.snack_color = "error";
            this.error_message =
              "You are not permitted to edit the link details, reach out to System Administrator";
          } else if (error == 500) {
            this.snack = true;
            this.snack_color = "error";

            this.error_message =
              "Something Went Wrong While Updating the Link Details";
          }
        });
    },

    sanitize_url(url) {
      let that = this;
      that.link_meta.longURL = url.replace(/\s/g, "");
    },

    fieldsChecker() {
      if (this.link_meta.longURL.length > 0) {
        if (this.link_meta.privacy_enabled && this.link_meta.hash.length > 0) {
          return true;
        } else if (!this.link_meta.privacy_enabled) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
