<template>
  <v-app>
    <v-main class="background">
      <v-container>
        <v-card class="elevation-0 transparent" align="center">
          <v-card-title>
            Site setup:
            {{ $store.state.config.license.sitename }}</v-card-title
          >
        </v-card>
        <v-stepper v-model="step" class="elevation-0 rounded-t-xl rounded-b-xl">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1">
              Welcome Information
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Setup Organization
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Verify and Continue </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="12" xl="6" lg="6">
                  <DummyCard></DummyCard>
                </v-col>
                <v-col cols="12" xl="6" lg="6">
                  <DummyCard></DummyCard>
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                rounded
                class="elevation-0"
                @click="step = 2"
              >
                Continue
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12 elevation-0">
                <v-row>
                  <v-col cols="12" xl="6" lg="6">
                    <DummyCard></DummyCard>
                  </v-col>
                  <v-col cols="12" xl="6" lg="6">
                    <v-card
                      class="elevation-0 rounded-t-xl rounded-b-xl"
                      align="center"
                    >
                      <v-img
                        :src="
                          sitedata.logo ||
                          'https://www.freepnglogos.com/uploads/logo-website-png/logo-website-file-globe-icon-svg-wikimedia-commons-21.png'
                        "
                        width="50%"
                      ></v-img>

                      <v-row class="mt-5">
                        <v-col cols="12" md="12">
                          <v-text-field
                            :counter="60"
                            outlined
                            v-model="sitedata.sitename"
                            style="border-radius: 10px"
                            label="Site Name"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="mt-n6">
                          <v-text-field
                            outlined
                            v-model="sitedata.owner_name"
                            style="border-radius: 10px"
                            label="Owner Name"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="mt-n6">
                          <v-text-field
                            outlined
                            v-model="sitedata.owner_email"
                            style="border-radius: 10px"
                            label="Owner Email"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="12" class="mt-n6">
                          <v-text-field
                            outlined
                            v-model="sitedata.siteurl"
                            style="border-radius: 10px"
                            label="Whitelisted Site URL"
                            required
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-btn
                color="primary"
                rounded
                class="elevation-0"
                :disabled="sitedatafilled"
                @click="registerSite()"
              >
                Register and Continue
              </v-btn>

              <v-btn color="primary" text class="elevation-0" @click="step++">
                Back
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>

              <v-btn color="primary" @click="$router.push('/')">
                Continue
              </v-btn>

              <v-btn text> Cancel </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import md5 from "md5";
import Admin from "../../services/Admin";
import DummyCard from "../../components/DummyCard.vue";
export default {
  components: {
    DummyCard,
  },
  data: () => ({
    step: 1,
    loading: false,
    sitedata: {
      hostname: window.location.origin,
      hash: md5(window.location.origin),
      owner_name: "",
      owner_email: "",
      logo: "",
      sitename: "",
      siteurl: "",
      siteLicense: "Active",
    },
  }),

  computed: {
    sitedatafilled() {
      return this.checkSiteFields();
    },
  },
  mounted() {
    this.showConfig();
  },
  methods: {
    checkSiteFields() {
      if (
        this.sitedata.owner_name === "" ||
        this.sitedata.owner_email === "" ||
        this.sitedata.sitename === "" ||
        this.sitedata.logo === ""
      ) {
        return true;
      } else {
        return false;
      }
    },

    showConfig() {
      this.sitedata.sitename = this.$store.state.config.license.sitename;
      this.sitedata.owner_name = this.$store.state.config.license.siteOwner;
      this.sitedata.owner_email =
        this.$store.state.config.license.siteOwner_email;
      this.sitedata.logo = this.$store.state.config.license.sitelogo;
      this.sitedata.siteurl = this.$store.state.config.license.siteurl;
    },

    registerSite() {
      console.log(this.sitedata);
      Admin.RegisterSite(this.sitedata)
        .then(() => {
          this.step++;
          localStorage.setItem("registered", true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
