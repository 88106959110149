<template>
  <div>
    <v-app v-if="$store.state.frameless.includes($route.path)">
      <router-view></router-view>
    </v-app>
    <v-app v-else>
      <v-snackbar bottom v-model="$store.state.snackbar.show">
        {{ $store.state.snackbar.header }}
      </v-snackbar>
      <v-app-bar
        app
        class="elevation-0 mt-3 ml-3 mr-3 mb-3"
        color="white"
        style="border-radius: 20px"
      >
        <v-toolbar-title class="px-0">
          <v-container fluid>
            <v-col class="px-0 mt-1">
              <img :src="$store.state.config.license.sitelogo" width="120px" />
            </v-col>
          </v-container>
        </v-toolbar-title>

        <v-btn
          v-for="(link, i) in $store.state.navigation"
          :key="i"
          :to="link.link"
          rounded
          class="ml-2 google-font hidden-sm-and-down this.$vuetify.theme.dark?'whiteText':'blackText'"
          style="text-transform: capitalize"
          text
        >
          <v-icon medium class="mr-2" color="button">{{ link.icon }}</v-icon>
          {{ link.name }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-avatar size="35" @click="user_profile = true">
          <v-img :src="'https://www.gravatar.com/avatar/' + avatar"></v-img>
        </v-avatar>
      </v-app-bar>
      <v-container class="pa-0" fluid>
        <router-view></router-view>
        <v-bottom-sheet v-model="user_profile">
          <v-sheet class="text-center rounded-t-xl" height="500px">
            <UserCard></UserCard
          ></v-sheet>
        </v-bottom-sheet>
        <v-footer absolute color="background" padless>
          <v-col class="text-center" cols="12">
            Built with love by
            <strong
              ><a
                href="https://codescapes.atharvaunde.dev"
                target="_blank"
                style="text-decoration: none"
                >Atharva Unde
              </a></strong
            >
          </v-col>
        </v-footer>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import md5 from "md5";
import UserCard from "./components/UserCard.vue";

export default {
  name: "App",
  components: {
    UserCard,
  },
  data: () => ({
    avatar: "",
    access_token: "",
    user_profile: false,
  }),
  beforeCreate() {
    localStorage.getItem("darkMode") == "true"
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
  },
  beforeMount() {
    this.$store.dispatch("checkMaintenance");
  },
  mounted() {
    this.$store
      .dispatch("setUp")
      .then((resp) => {
        if (resp.data.status === "Active") {
          console.log("Active License Found");
        } else {
          console.log(
            "No Active License Found, current status: " + resp.data.status
          );
          this.$router.push("/onboard/init/");
        }
      })
      .catch(() => {
        this.$router.push("/onboard/no-license/");
      });
    if (localStorage.getItem("accessToken")) {
      this.access_token = localStorage.getItem("accessToken");
      this.avatar = md5(localStorage.getItem("username"));
      this.verify();
    }
  },
  computed: {},
  created() {},
  methods: {
    verify() {
      let that = this;
      that.$store
        .dispatch("Verify_Token")
        .then(() => {})
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();
            that.$router.replace("/");
          }
        });
    },

    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
<style></style>
