<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-row fluid justify="center">
          <v-col cols="12" lg="2" xl="2">
            <UserCardManage
              :name="userdetails.firstname + ' ' + userdetails.lastname"
              :username="username"
            ></UserCardManage>
          </v-col>
          <v-col cols="12" lg="9" xl="9">
            <v-tabs centered>
              <v-tab>User Metadata</v-tab>
              <v-tab>Roles and Permissions</v-tab>
              <v-tab-item>
                <v-col cols="12">
                  <v-card class="elevation-0" style="border-radius: 10px">
                    <v-card-title> User Details </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" xl="6">
                          <v-text-field
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.firstname"
                            label="First Name"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6">
                          <v-text-field
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.lastname"
                            label="Last Name"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" class="mt-n6">
                          <v-text-field
                            :append-icon="'mdi-account'"
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.username"
                            disabled
                            label="Username / Email"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" class="mt-n6">
                          <v-text-field
                            :append-icon="'mdi-domain'"
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.com_unit_id"
                            label="User Cohert"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" class="mt-n6">
                          <v-text-field
                            :append-icon="'mdi-domain'"
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.role"
                            label="User Role"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" xl="6" class="mt-n6">
                          <v-text-field
                            :append-icon="'mdi-domain'"
                            style="border-radius: 10px"
                            color="primary"
                            v-model="userdetails.isActive"
                            label="Account Status"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-col cols="12">
                  <v-card
                    class="elevation-2 rounded-t-xl rounded-b-xl"
                    style="border-radius: 10px"
                  >
                    <v-card-title>
                      <v-text-field
                        outlined
                        rounded
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="userPermissions"
                      :search="search"
                    >
                      <template v-slot:items="props">
                        <td class="text-xs-left">
                          {{ props.item.name }}
                        </td>
                        <td class="text-xs-left">
                          {{ props.item.description }}
                        </td>
                      </template>
                      <template v-slot:item.actions="props">
                        <v-btn
                          icon
                          medium
                          text
                          @click="setPermissions(props.item)"
                        >
                          {{ props.item.status ? "✅" : "❌" }}
                          <!-- <v-icon
                            color="red"
                            medium
                            v-if="props.item.status === 0"
                            >mdi-window-close
                          </v-icon>
                          <v-icon
                            color="green"
                            medium
                            v-if="props.item.status === 1"
                            >mdi-checkbox-marked-outline
                          </v-icon> -->
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Admin from "@/services/Admin.js";
import UserCardManage from "../../components/UserCardManage.vue";
export default {
  components: {
    UserCardManage,
  },
  data: () => ({
    accessToken: "",
    username: "",
    userPermissions: [],
    userdetails: {
      username: "",
      password: "",
      firstname: "",
      lastname: "",
      avatar: "",
      role: "",
      role_id: "",
      isActive: "",
      id: "",
      com_unit_id: null,
    },
    headers: [
      { text: "Role Name", value: "name", sortable: true },
      {
        text: "Role Description",
        value: "description",
        sortable: true,
      },
      {
        text: "Action Permitted?",
        value: "actions",
        align: "center",
      },
    ],
    search: "",
  }),
  created() {},
  beforeMount() {},
  mounted() {
    this.getUsers();
    this.getPermissions();
  },
  methods: {
    getUsers() {
      let that = this;
      that.username = that.$route.params.id;
      that.accessToken = localStorage.getItem("accessToken");
      Admin.GetUserData(that.username, that.accessToken).then(function (
        response
      ) {
        that.userdetails = response;
      });
    },

    getPermissions() {
      let that = this;
      that.accessToken = localStorage.getItem("accessToken");
      Admin.GetUserPermissions(that.username, that.accessToken).then(function (
        response
      ) {
        that.userPermissions = response;
      });
    },

    setPermissions(item) {
      let that = this;
      var __active = null;
      if (item.status === 1) {
        __active = 0;
      } else {
        __active = 1;
      }
      Admin.SetUserPermissions(
        {
          username: that.username,
          permission_id: item.permission_id,
          status: __active,
        },
        that.accessToken
      ).then(function (response) {
        console.log(response);
        that.getPermissions();
      });
    },

    deleteUser() {
      let that = this;
      Admin.DeleteUser(that.username, that.accessToken).then(function (
        response
      ) {
        console.log(response);
        that.$router.push("/admin/users");
      });
    },
  },
};
</script>
