import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import Promise from "promise";
import md5 from "md5";
// import io from "socket.io-client";

Vue.use(Vuex);

const API_URL = process.env.VUE_APP_API_Host;

axios.defaults.headers.common["x-access-token"] =
  localStorage.getItem("accessToken");

export default new Vuex.Store({
  state: {
    auth: {
      isLoggedIn: false,
      accessToken: null || localStorage.getItem("accessToken"),
    },

    user: {
      username: null || localStorage.getItem("username"),
      name: null || localStorage.getItem("name"),
      com_unit_id: null || localStorage.getItem("com_unit_id"),
    },

    config: {} || localStorage.getItem("config"),

    maintenance: {
      code: null,
      end_time: "",
      flag: "",
      message: "",
      start_time: "",
      status: "",
    },

    navigation: [
      {
        name: "Links",
        link: "/links",
        icon: "mdi-link",
        disabled: false,
      },
    ],

    frameless: [
      "/",
      "/Login_Trouble",
      "/onboard/init/",
      "/onboard/no-license/",
    ],

    userheaders: [
      {
        text: "First Name",
        value: "firstname",
      },
      {
        text: "Last Name",
        value: "lastname",
      },
      {
        text: "Username",
        sortable: false,
        value: "username",
      },
      {
        text: "Role",
        sortable: false,
        value: "role",
      },
      {
        text: "Account Status",
        sortable: false,
        value: "isactive",
        align: "center",
      },
      {
        text: "Department",
        sortable: true,
        value: "unit",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
      },
    ],

    linkheaders: [
      {
        text: "Long URL",
        align: "start",
        sortable: false,
        value: "longURL",
      },
      { text: "Short URL", value: "shortURL", align: "left" },
      { text: "Privacy", value: "privacy_enabled", align: "center" },
      { text: "Created At", value: "linkcreatedAt", align: "center" },
      { text: "Created By", value: "createdBy", align: "center" },
      { text: "User Cohort", value: "com_unit_name", align: "center" },

      {
        align: "center",
        text: "Actions",
        value: "actions",
      },
    ],

    snackbar: {
      header: null,
      action: null,
      show: false,
    },
  },

  getters: {
    auth_status(state) {
      return state.auth.accessToken != null;
    },
  },

  headers: {
    "Content-Type": "application/json",
    "x-access-token": localStorage.getItem("accessToken"),
  },

  mutations: {
    setAuth(state, payload) {
      state.auth.isLoggedIn = true;
      state.auth.accessToken = payload.token;
      state.user.username = payload.user.username;
      state.user.name = payload.user.firstname + " " + payload.user.lastname;
      state.user.com_unit_id = payload.user.com_unit_id;
      localStorage.setItem("auth_status", true);
      localStorage.setItem("accessToken", payload.token);
      localStorage.setItem("username", payload.user.username);
      localStorage.setItem("avatar", payload.user.avatar);
      localStorage.setItem(
        "name",
        payload.user.firstname + " " + payload.user.lastname
      );
    },

    setLogout(state) {
      localStorage.clear();
      state.auth.isLoggedIn = false;
      state.auth.accessToken = null;
      state.user.username = null;
      state.user.name = null;
      state.user.com_unit_id = null;
    },

    destroyToken(state) {
      localStorage.clear();
      state.auth.isLoggedIn = false;
      state.auth.accessToken = null;
      state.user.username = null;
      state.user.name = null;
      state.user.com_unit_id = null;
    },

    snackbar(state, payload) {
      // log("> Mutating snackbar");
      state.snackbar.action = payload.action;
      state.snackbar.header = payload.header;
      state.snackbar.show = true;
    },

    setMaintenance(state, payload) {
      state.maintenance.code = payload.code;
      state.maintenance.start_time = payload.start_time;
      state.maintenance.end_time = payload.end_time;
      state.maintenance.status = payload.status;
      state.maintenance.flag = payload.flag;
      state.maintenance.message = payload.message;
    },

    setConfig(state, payload) {
      state.config = payload;

      localStorage.setItem("config", JSON.stringify(payload));
    },
  },

  actions: {
    async login(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(API_URL + "/api/auth/login", payload)
          .then((res) => {
            resolve(res);
            // var socket = io(API_URL, {
            //   query: `token=${res.data.token}`,
            // });

            // // socket@disconnect
            // socket.on("disconnect", function () {
            //   console.log("Socket disconnect");
            //   context.commit("snackbar", {
            //     action: "",
            //     header: "Connection Lost",
            //     icon: "mdi-cloud-outline-off",
            //   });
            // });

            // // socket@connect
            // socket.on("connect", function () {
            //   console.log("Socket Connected");
            //   context.commit("snackbar", {
            //     action: "",
            //     header: "Connection Established",
            //     icon: "mdi-cloud-outline",
            //   });
            // });

            context.commit("setAuth", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async forgetpassword(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(API_URL + "/api/forgetpassword", payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async reset_password(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(API_URL + "/api/resetpassword", payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async Verify_Token() {
      return new Promise((resolve, reject) => {
        axios
          .post(API_URL + "/api/auth/verify", {
            PERMISSION_ROLE: 9,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async FetchURLS() {
      return new Promise((resolve, reject) => {
        axios
          .get(API_URL + "/api/link/list")
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async destroyToken(context) {
      if (context.getters.auth_status) {
        return new Promise((resolve) => {
          localStorage.clear();
          context.commit("destroyToken");
          resolve();
        });
      }
    },

    async checkMaintenance(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(API_URL + "/api/health/Maintenance")
          .then((res) => {
            resolve(res);
            context.commit("setMaintenance", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async setUp(context) {
      console.log(window.location.origin);
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL + "/api/config/get?client_id=" + md5(window.location.origin)
          )
          .then((res) => {
            resolve(res);
            context.commit("setConfig", res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  modules: {},
});
