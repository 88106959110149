<template>
  <v-main>
    <v-dialog v-model="forget_dialog" max-width="400" class="backgrond">
      <v-card style="border-radius: 26px">
        <v-card-title>
          <v-spacer></v-spacer
          ><v-icon color="#21005D" class="mt-3" size="30">mdi-update</v-icon>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-title class="text-h6 mt-n4" style="text-align: center">
          Forgot your Password?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-n7"
            color="button"
            text
            @click.stop="forget_password()"
            :disabled="!username"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="4000" :color="snackbar_color" app>
      {{ snackbar_message }}
    </v-snackbar>
    <v-container class="pa-0 ma-0" fill-height fluid grid-list-md>
      <v-layout align-stretch class="ma-0" row wrap>
        <v-col
          style="background-color: #f9faff"
          md="7"
          sm="12"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-container class fill-height fluid grid-list-md>
            <v-layout align-center row wrap justify-center>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <h2
                    class="text-h4"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Welcome to CareerLabs!
                  </h2>
                </v-row>
                <v-row align="center" justify="center" class="mt-6">
                  <h2
                    class="text-h5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Join Us To Build Your Dream Career.
                  </h2>
                </v-row>
              </v-col>
              <v-carousel
                :show-arrows="false"
                cycle
                hide-delimiters
                interval="10000"
              >
                <v-carousel-item
                  contain
                  v-for="(item, i) in items"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
              <v-col cols="12" class="ml-12">
                <h2
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                  "
                >
                  Build Your Dream Career
                </h2>
                <h4
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  It is a long established fact that a reader<br />
                  will be distracted by layout.
                </h4>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
        <v-col class="elevation-0" md="5" sm="12">
          <v-container fill-height fluid grid-list-md>
            <v-layout align-center class row wrap align="center" justify-center>
              <v-col>
                <v-card class="mx-auto elevation-0" width="400">
                  <h1
                    class="text-h4 mb-5"
                    style="
                      color: #2196f3;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                    "
                  >
                    Log In.
                  </h1>
                  <h1
                    class="text-h6 mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Enter your Email and Password to access URL Portal&#8482;
                  </h1>
                  <v-form class>
                    <v-text-field
                      label="Email ID"
                      required
                      v-model="username"
                      outlined
                      style="border-radius: 10px"
                      :rules="emailRules"
                      class="mt-5"
                    ></v-text-field>
                    <v-text-field
                      @click:append="show_pass = !show_pass"
                      :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show_pass ? 'text' : 'password'"
                      label="Password"
                      required
                      style="border-radius: 10px"
                      outlined
                      :rules="mandatoryRules"
                      v-model="password"
                      class=""
                    ></v-text-field>

                    <v-layout row wrap class="mt-n4">
                      <v-row align="end" justify="end">
                        <!-- <v-col cols="6">
                          <v-btn
                            text
                            style="
                              color: #0645ad;
                              font-family: 'Montserrat', sans-serif;
                              font-weight: 600;
                            "
                            >Lost password?
                          </v-btn>
                        </v-col> -->
                        <v-col cols="12">
                          <v-btn
                            class="mt-5"
                            @click.stop="mern_login()"
                            block
                            large
                            rounded
                            :loading="loading"
                            color="primary"
                            >Log In
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-form>
                </v-card>
                <!-- <v-footer absolute color="transparent">
                  <v-spacer> </v-spacer
                  ><v-btn icon @click="info_flag = true"
                    ><v-icon color="black"
                      >mdi-information-outline</v-icon
                    ></v-btn
                  >
                </v-footer> -->
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
        <v-snackbar v-model="snackbar" color="primary" timeout="5000">
          {{ snackbar_message }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-layout>
      <v-dialog v-model="info_flag" max-width="400">
        <v-card>
          <v-card-title class="text-h5">
            CareerLabs URL Shortener
          </v-card-title>

          <v-card-text>
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </v-card-text>

          <v-card-actions>
            <v-btn color="green darken-1" text @click="info_flag = false">
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data: () => ({
    username: "",
    password: "",
    otp: "",
    loading: false,
    show_pass: false,
    desktop_dialog: true,
    forget_dialog: false,
    info_flag: false,
    snackbar: false,
    snackbar_color: "",
    snackbar_message: "",

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    items: [
      {
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/businessman-doing-work-from-home-4637852-3864098.png",
      },
      {
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/investor-getting-profit-from-investment-3592920-3016814@0.png",
      },
    ],
    mandatoryRules: [(v) => !!v || "This field is required"],
  }),

  mounted() {
    if (localStorage.getItem("accessToken")) {
      this.access_token = localStorage.getItem("accessToken");
      this.verify();
    }
  },
  methods: {
    mern_login() {
      let that = this;
      that.loading = true;
      that.$store
        .dispatch("login", {
          username: that.username.toLowerCase(),
          password: that.password,
        })
        .then(function (res) {
          if (res.status === 200) {
            that.$router.replace("/links");
          }
          that.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            that.snackbar = true;
            that.snackbar_message = "Invalid Credentials";
            that.snackbar_color = "warning";
          } else if (error.response.status === 404) {
            that.snackbar = true;
            that.snackbar_message = "User Not Found";
            that.snackbar_color = "error";
          } else {
            that.snackbar = true;
            that.snackbar_message = "Something Went Wrong";
            that.snackbar_color = "error";
          }
          that.loading = false;
        });
    },

    forget_password() {
      let that = this;

      that.$store
        .dispatch("forgetpassword", {
          username: that.username,
        })
        .then(function (res) {
          if (
            res.data.message === "OTP sent to your email" &&
            res.status === 200
          ) {
            that.forget_dialog = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert("User not found in system");
          } else if (error.response.status === 500) {
            alert("something went wrong, try again later");
          }
        });
    },

    reset_password() {
      let that = this;
      that.$store
        .dispatch("reset_password", {
          username: that.username,
          password: that.password,
          otp: that.otp,
        })
        .then(function () {})
        .catch((error) => {
          console.log(error);
        });
    },

    verify() {
      let that = this;
      that.$store
        .dispatch("Verify_Token")
        .then((res) => {
          if (res.status === 200) {
            that.$router.replace("/links");
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 401) {
            localStorage.clear();
            that.snack = true;
            that.$router.replace("/");
          }
        });
    },
  },
};
</script>

<style scoped></style>
