import axios from "axios";
import store from "../store/index.js";
const API_URL = process.env.VUE_APP_API_Host;

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["x-access-token"] =
  localStorage.getItem("accessToken") || store.state.auth.accessToken;

let Admin = {
  ListUsers: async (access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/auth/list`, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  GetUserData: async (username, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/auth/user`,
          {
            username: username,
          },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response.data.user);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  GetUserPermissions: async (username, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/index/getPermissions`,
          {
            username: username,
            attributes: "*",
          },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  SetUserPermissions: async (payload, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/index/setPermissions`, payload, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  DeleteUser: async (username, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/auth/delete`,
          {
            username: username,
          },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  CreateUser: async (payload, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/auth/register`, payload, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  ListUnits: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/auth/units`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  SeedPermissions: async (payload, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/index/initPermissions`, payload, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  CheckMaintenance: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/health/Maintenance`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  RegisterSite: async (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/config/activate`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

export default Admin;
