<template>
  <v-snackbar v-model="snackbar" :timeout="timeout" :color="color">
    {{ text }} {{ snack_color }}
    <template v-slot:action="{ attrs }">
      <v-btn
        class="text--primary"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: ["text", "timeout", "color"],
  data: () => ({
    snackbar: true,
  }),
  methods: {},
};
</script>
