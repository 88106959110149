<template>
  <v-banner
    v-if="alert_show"
    two-line
    color="white"
    class="elevation-0"
    style="border-radius: 15px"
  >
    <v-avatar slot="icon" color="deep-purple accent-4" size="40">
      <v-icon icon="mdi-lock" color="white"> mdi-lock </v-icon>
    </v-avatar>

    Three line text string example with two actions. One to two lines is
    preferable. Three lines should be considered the maximum string length on
    desktop in order to keep messages short and actionable.

    <template v-slot:actions>
      <v-btn text color="deep-purple accent-4"> Action </v-btn>
      <v-btn text color="deep-purple accent-4" @click="dismiss_alert()">
        Dismiss
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  name: "AlertBanner",
  props: [],

  data() {
    return {
      alert_show: false,
    };
  },

  methods: {
    dismiss_alert() {
      this.alert_show = false;
    },
  },
  mounted() {
    this.alert_show = false;
  },
};
</script>
