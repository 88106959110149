import axios from "axios";
import store from "../store/index.js";
const API_URL = process.env.VUE_APP_API_Host;

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common["x-access-token"] =
  localStorage.getItem("accessToken") || store.state.auth.accessToken;

let API = {
  ListURLs: async (access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/link/list`, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  FetchLinkData: async (link_id, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/link/get",
          { id: link_id },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  CreateLink: async (link_data, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URL + "/api/link/create", link_data, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  DeleteLink: async (link_id, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/link/delete",
          { id: link_id },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  UpdateLink: async (link_data, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .put(API_URL + "/api/link/update", link_data, {
          headers: {
            "x-access-token": access_token,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  CheckDuplicate: async (shortCode, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/link/check",
          { shortCode: shortCode },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  RenderGraph: async (link_id, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/analytics/get",
          { siteID: link_id },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  DeleteLog: async (link_id, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/analytics/delete",
          { siteID: link_id },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },

  RawLgs: async (link_id, access_token) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          API_URL + "/api/analytics/getRaw",
          { siteID: link_id },
          {
            headers: {
              "x-access-token": access_token,
            },
          }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

export default API;
