<template>
  <v-main>
    <v-snackbar v-model="snackbar" :timeout="4000" :color="snackbar_color" app>
      {{ snackbar_message }}
    </v-snackbar>
    <v-container class="pa-0 ma-0" fill-height fluid grid-list-md>
      <v-layout align-stretch class="ma-0" row wrap>
        <v-col
          style="background-color: #f9faff"
          md="7"
          sm="12"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-container class fill-height fluid grid-list-md>
            <v-layout align-center row wrap justify-center>
              <v-col cols="12">
                <v-row align="center" justify="center">
                  <h2
                    class="text-h4"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 600;
                    "
                  >
                    Welcome to CareerLabs!
                  </h2>
                </v-row>
                <v-row align="center" justify="center" class="mt-6">
                  <h2
                    class="text-h5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Join Us To Build Your Dream Career.
                  </h2>
                </v-row>
              </v-col>
              <v-carousel
                :show-arrows="false"
                cycle
                hide-delimiters
                interval="10000"
              >
                <v-carousel-item
                  contain
                  v-for="(item, i) in items"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
              <v-col cols="12" class="ml-12">
                <h2
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                  "
                >
                  Build Your Dream Career
                </h2>
                <h4
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  It is a long established fact that a reader<br />
                  will be distracted by layout. #faceplam
                </h4>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
        <v-col class="elevation-0" md="5" sm="12">
          <v-container fill-height fluid grid-list-md>
            <v-layout align-center class row wrap align="center" justify-center>
              <v-col>
                <v-card class="mx-auto elevation-0" width="450">
                  <h1
                    class="text-h4 mb-5"
                    style="
                      color: #052a4e, 100%;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                    "
                  >
                    Forgot Password?
                  </h1>
                  <h1
                    class="text-h6 mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Enter your registered email below to receive password reset
                    instruction
                  </h1>
                  <v-form class="mt-12">
                    <v-text-field
                      label="Email ID"
                      required
                      outlined
                      style="border-radius: 10px"
                      v-model="username"
                      class="mt-5"
                    ></v-text-field>

                    <v-layout row wrap>
                      <v-row align="end" justify="end">
                        <v-col cols="12">
                          <v-btn
                            block
                            large
                            rounded
                            :loading="loading"
                            color="primary"
                            >Send Password Reset Link</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-form>
                </v-card>
                <v-card
                  class="mx-auto elevation-0"
                  width="450"
                  v-if="reset_email_sent"
                >
                  <h1
                    class="text-h4 mb-5"
                    style="
                      color: #052a4e, 100%;
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 700;
                    "
                  >
                    Email has been sent!
                  </h1>
                  <h1
                    class="text-h6 mb-5"
                    style="
                      font-family: 'Montserrat', sans-serif;
                      font-weight: 400;
                    "
                  >
                    Please check your inbox and click on the received link to
                    reset password
                  </h1>
                  <img
                    src="https://github.com/CL-Technologies/student-portal/blob/router_v2/src/assets/misc/mail_sent.png?raw=true"
                  />
                </v-card>
                <v-footer absolute color="transparent">
                  <v-spacer> </v-spacer
                  ><v-btn icon @click="info_flag = true"
                    ><v-icon color="black"
                      >mdi-information-outline</v-icon
                    ></v-btn
                  >
                </v-footer>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
        <v-snackbar v-model="snackbar" color="primary" timeout="5000">
          {{ snackbar_message }}
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Login",
  components: {},
  data: () => ({
    username: "",
    password: "",
    otp: "",
    loading: false,
    show_pass: false,
    desktop_dialog: true,
    forget_dialog: false,
    info_flag: false,
    snackbar: false,
    snackbar_color: "",
    snackbar_message: "",

    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    items: [
      {
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/businessman-doing-work-from-home-4637852-3864098.png",
      },
      {
        src: "https://cdn3d.iconscout.com/3d/premium/thumb/investor-getting-profit-from-investment-3592920-3016814@0.png",
      },
    ],
    mandatoryRules: [(v) => !!v || "This field is required"],
  }),

  mounted() {
    if (localStorage.getItem("accessToken")) {
      this.access_token = localStorage.getItem("accessToken");
      this.verify();
    }
  },
  methods: {
    mern_login() {
      let that = this;
      that.loading = true;
      that.$store
        .dispatch("login", {
          username: that.username.toLowerCase(),
          password: that.password,
        })
        .then(function (res) {
          if (res.status === 200) {
            that.$router.replace("/links");
          }
          that.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            that.snackbar = true;
            that.snackbar_message = "Invalid Credentials";
            that.snackbar_color = "warning";
          } else if (error.response.status === 404) {
            that.snackbar = true;
            that.snackbar_message = "User Not Found";
            that.snackbar_color = "error";
          } else {
            that.snackbar = true;
            that.snackbar_message = "Something Went Wrong";
            that.snackbar_color = "error";
          }
          that.loading = false;
        });
    },

    forget_password() {
      let that = this;

      that.$store
        .dispatch("forgetpassword", {
          username: that.username,
        })
        .then(function (res) {
          if (
            res.data.message === "OTP sent to your email" &&
            res.status === 200
          ) {
            that.forget_dialog = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            alert("User not found in system");
          } else if (error.response.status === 500) {
            alert("something went wrong, try again later");
          }
        });
    },

    reset_password() {
      let that = this;
      that.$store
        .dispatch("reset_password", {
          username: that.username,
          password: that.password,
          otp: that.otp,
        })
        .then(function () {})
        .catch((error) => {
          console.log(error);
        });
    },

    verify() {
      let that = this;
      that.$store
        .dispatch("Verify_Token")
        .then((res) => {
          if (res.status === 200) {
            that.$router.replace("/links");
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 401) {
            localStorage.clear();
            that.snack = true;
            that.$router.replace("/");
          }
        });
    },
  },
};
</script>

<style scoped></style>
