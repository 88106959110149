<template>
  <v-app>
    <v-main class="background">
      <v-container fluid>
        <AlertBanner></AlertBanner>
        <v-row class="mt-1" justify="center">
          <v-col cols="12" xl="8">
            <v-row>
              <v-col
                :key="index"
                cols="6"
                xl="3"
                sm="12"
                v-for="index in 4"
                xs="12"
              >
                <v-card
                  class="elevation-0 rounded-t-xl rounded-b-xl my-2 btn-hover color-1 pa-5"
                  ripple
                  link
                  @click.stop="$router.push('/tnc')"
                >
                  <v-icon x-large color="white"
                    >mdi-rocket-launch-outline</v-icon
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AlertBanner from "../components/AlertBanner.vue";

export default {
  components: {
    AlertBanner,
  },

  data: () => ({}),
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.svg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.shadow {
  border-radius: 15px;
  box-shadow: 5px 6px 6px #888888;
}
.btn-hover {
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-hover:focus {
  outline: none;
}
.btn-hover.color-1 {
  background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}
</style>
