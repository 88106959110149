<template>
  <v-app>
    <v-main class="background">
      <v-container fluid class="mt-5">
        <v-row fluid>
          <v-col cols="12" md="3" v-for="(data, index) in options" :key="index">
            <v-card
              class="feature-box"
              ripple
              outlined
              style="border-radius: 15px"
            >
              <v-card-title class="text-h5">
                <v-icon class="mr-2" size="30">{{ data.icon }}</v-icon>
                {{ data.name }}
              </v-card-title>

              <v-card-subtitle
                >Listen to your favorite artists and albums whenever and
                wherever, online and offline.</v-card-subtitle
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    options: [
      {
        name: "Users",
        icon: "mdi-account-outline",
        url: "/users",
        disabled: false,
      },
      {
        name: "Domains",
        icon: "mdi-earth",
        url: "/domains",
        disabled: false,
      },
      {
        name: "Roles and Permissions",
        icon: "mdi-account-key",
        url: "/roles",
        disabled: false,
      },
      {
        name: "Settings",
        icon: "mdi-cog",
        url: "/settings",
        disabled: false,
      },
      {
        name: "Reports",
        icon: "mdi-poll",
        url: "/reports",
        disabled: false,
      },
      {
        name: "Organizational Units",
        icon: "mdi-domain",
        url: "/organizational-units",
        disabled: false,
      },
    ],
  }),
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.feature-box:hover {
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
}
</style>
