<template>
  <v-card class="elevation-0 py-8 rounded-t-xl rounded-b-xl" align="center">
    <v-avatar size="95" @click="sheet = true">
      <v-img :src="'https://www.gravatar.com/avatar/' + avatar"></v-img>
    </v-avatar>
    <p
      class="text-h5 mt-5"
      style="
        color: #3368d7;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
      "
    >
      {{ name }}
    </p>
    <v-card-subtitle class="mt-n7"> {{ username }} </v-card-subtitle>
    <v-card-actions>
      <v-row>
        <v-col cols="12">
          <v-btn class="elevation-0" color="warning" outlined rounded>
            Invalidate Session(s)
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-n3">
          <v-btn
            class="elevation-0"
            color="error"
            @click="deleteUser()"
            rounded
          >
            Delete User
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import md5 from "md5";
export default {
  name: "UserCard",
  props: ["name", "username"],
  data() {
    return {
      avatar: "",
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
  mounted() {
    this.avatar = md5(this.username);
  },
};
</script>
