<template>
  <v-main>
    <v-container class="pa-0 ma-0" fill-height fluid grid-list-md>
      <v-layout
        align-stretch
        class="ma-0"
        row
        wrap
        align="center"
        justify-center
      >
        <v-col class="elevation-0" md="5" sm="12">
          <v-container fill-height fluid grid-list-md>
            <v-layout align-center class row wrap align="center" justify-center>
              <v-col>
                <v-card
                  class="mx-auto elevation-0 rounded-t-xl rounded-b-xl"
                  outlined
                  max-width="500"
                  align="center"
                >
                  <v-window>
                    <v-window-item>
                      <v-card-text>
                        <v-img
                          src="https://cdn3d.iconscout.com/3d/premium/thumb/no-search-result-5000896-4165671.png"
                          lazy-src="https://cdn3d.iconscout.com/3d/premium/thumb/no-search-result-5000896-4165671.png"
                          contain
                        >
                        </v-img>
                      </v-card-text>
                    </v-window-item>
                  </v-window>
                </v-card>
              </v-col>
            </v-layout>
          </v-container>
        </v-col>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({}),

  computed: {},
  methods: {},
};
</script>
